<template>
  <figure class="highlight">
    <b-spinner label="Loading..." v-if="loading"></b-spinner>

    <b-container class="mt-6" style="padding-top: 2rem" fluid v-if="!loading">
      <h3 class="py-2">
        <span class="text-capitalize">Previous Parts Orders</span>
        <small class="text-muted">&nbsp;&nbsp;</small>
      </h3>
      <!-- <b-alert show variant="danger" v-if="!canOrder"
          >&nbsp; {{ orderWarning }} &nbsp;
          <b-button v-b-toggle.collapse-1 variant="primary"
            >View Invoices</b-button
          ></b-alert
        >-->

      <b-alert
        :show="dismissCountDown"
        dismissible
        variant="success"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
      >
        <p>Status updated successfully</p>
        <b-progress
          variant="success"
          :max="dismissSecs"
          :value="dismissCountDown"
          height="4px"
        ></b-progress>
      </b-alert>

      <b-alert
        :show="dismissCountDownFail"
        dismissible
        variant="danger"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChangedFail"
      >
        <p>Error updating status</p>
        <b-progress
          variant="danger"
          :max="dismissSecs"
          :value="dismissCountDownFail"
          height="4px"
        ></b-progress>
      </b-alert>

      <div>
        <b-collapse id="collapse-1" class="mt-2">
          <b-card>
            <p class="card-text">Collapse contents Here</p>
            <b-button v-b-toggle.collapse-1-inner size="sm"
              >Toggle Inner Collapse</b-button
            >
            <b-collapse id="collapse-1-inner" class="mt-2">
              <b-card>Hello!</b-card>
            </b-collapse>
          </b-card>
        </b-collapse>
      </div>

      <div class="orders">
        <p class="text-center mt-5" v-if="loading">
          <b-spinner label="Loading..."></b-spinner>
        </p>
        <div class="p-5" v-else>
          <b-nav-form>
            <b-form-input
              class="mr-sm-2"
              placeholder="Filter"
              v-model="filter"
            ></b-form-input>
            <b-button
              size="sm"
              class="my-2 my-sm-0"
              v-if="filter"
              @click="filter = ''"
              >Clear</b-button
            >
          </b-nav-form>
          <b-table
            show-empty
            small
            stacked="md"
            :items="pendingOrders"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filterIncludedFields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
            class="mt-3"
          >
            <template v-slot:cell(DeliveryDate)="row">
              {{ row.value | onlyDate }}
            </template>

            <template v-slot:cell(actions)="row">
              <b-button
                size="md"
                @click="handleClick(row.item.OrderID)"
                class="mr-1 btn-black"
                variant="danger"
                >Details
              </b-button>
            </template>
          </b-table>

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-pagination"
          ></b-pagination>

          <hr />
        </div>
        <!-- title="'Order Confirmation - ' + clickedCoupon.OrderNumber" -->
        <b-modal
          v-model="detailsModal"
          size="xl"
          :title="'Details for Order '"
          hideFooter
          centered
        >
          <table
            class="
                    table
                    b-table
                    cart-table
                    table-striped table-bordered table-hover
                  "
          >
            <thead>
              <tr>
                <th>Item</th>
                <th>Colour</th>
                <th>Unit(s)</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr
                role="row"
                v-for="(item, i) in clickedCoupon.orderDetails"
                :key="i"
              >
                <td>{{ item.ItemNo }}</td>
                <td>{{ item.ItemName }}</td>
                <td>{{ item.OrderQty }}</td>
                <td>
                  {{
                    (parseFloat(item.UnitPrice) * parseFloat(item.OrderQty))
                      | amount
                  }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <!-- <tr>
                <th colspan="3">
                  <h4 style="text-align: right">Grand Total:</h4>
                </th>
                <th>
                  <h4>{{ clickedCoupon.OrderTotal | amount }}</h4>
                </th>
              </tr> -->
            </tfoot>
            <!---->
          </table>
        </b-modal>
      </div>

      <!-- <div class="about">
          <h2>Pending Orders</h2>
          <div class="col-md-11">
            <table class="table table-striped table-bordered" id="orderinfo">
              <thead>
                <tr>
                  <th style="width:8px;">
                    <input
                      type="checkbox"
                      class="group-checkable"
                      data-set="#orderinfo .checkboxes"
                    />
                  </th>
                  <th>Order Number</th>
                  <th class="hidden-phone">Date</th>
                  <th class="hidden-phone">Amount</th>
                  <th class="hidden-phone">Time Left</th>
                  <th class="hidden-phone"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="data in pendingOrders" v-bind:key="data.OrderID">
                  <td>
                    <input type="checkbox" class="checkboxes" value="1" />
                  </td>
                  <td>
                    <a
                      v-on:click="chooseorder(data)"
                      href="#myModal1"
                      role="button"
                      data-toggle="modal"
                      >{{ data.OrderNumber }}</a
                    >
                  </td>
                  <td class="center hidden-phone">
                    {{ data.OrderDate | formatDate }}
                  </td>
                  <td class="hidden-phone">{{ data.OrderTotal | amount }}</td>
                  <td class="hidden-phone"></td>
  
                  <td class="hidden-phone">
                    <span class="label label-danger">Pending Payment</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>-->
    </b-container>
  </figure>
</template>

<script>
// @ is an alias to /src
//import allCars from "../data/allCars";
// eslint-disable-next-line no-unused-vars
import FileService from "../service/fileUpload";
import VehicleService from "../service/vehicle";
import { getData } from "./../utils/storage";

export default {
  data() {
    return {
      dismissSecs: 10,
      dismissCountDown: 0,
      dismissCountDownFail: 0,
      tabIndex: 0,
      fields: [
        {
          key: "sNo",
          label: "",
          sortable: true,
          sortDirection: "asc"
        },
        {
          key: "OrderDate",
          label: "Order Date",
          sortable: true,
          sortDirection: "desc",
          formatter: "formatDate"
        },
        {
          key: "FullName",
          label: "Order By",
          sortable: true,
          sortDirection: "desc",
          formatter: ""
        } /*,
          {
            key: "OwnersName",
            label: "Time Left",
            sortable: true,
            sortDirection: "desc"
          },
          {
            key: "PaymentStatus",
            label: "Status",
            sortable: true,
            sortDirection: "desc"
          }*/,
        { key: "actions", label: "Actions" }
      ],
      gettingCoupons: true,
      clickedCoupon: {},
      detailsModal: false,
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      pageOptions: [5, 10, 15],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      dealerid: 0,
      pendingOrders: [],
      pendingOrderDetails: [],
      loading: false,
      selectedorder: {},
      document: null,
      confirmPayment: false,
      displayErrorMessage: false,
      canOrder: true,
      limitInfo: {},
      customerBalance: 0,
      negativeBalance: true,
      remainingLimit: 0,
      exceededLimit: false
    };
  },
  created() {
    this.canOrder = getData("canOrder");
    const loggedInUser = getData("userInfo");
    this.dealerid = loggedInUser.DealerID;
    console.log("logged In User is: ", loggedInUser);
    this.getpreviousOrders();
  },
  methods: {
    showToast(variant = "danger") {
      this.$bvToast.toast(
        "You have overdue invoices." + this.orderNoteWarning,
        {
          title: `Overdue Invoices`,
          variant: variant,
          solid: true,
          noAutoHide: true
        }
      );
    },
    getpreviousOrders: async function() {
      this.loading = true;
      const { data } = await VehicleService.getPreviousPartsOrder(
        this.dealerid
      );
      this.loading = false;
      this.pendingOrders = data.Data.Orders;
      this.pendingOrderDetails = data.Data.OrderDetails;
      this.totalRows = this.pendingOrders.length;
    },
    handleClick(id) {
      this.clickedCoupon = this.pendingOrders.filter(n => n.OrderID === id)[0];
      this.clickedCoupon.orderDetails = this.pendingOrderDetails.filter(
        n => n.OrderID === id
      );
      console.log(this.clickedCoupon);
      this.selectedorder.OrderID = id;
      this.selectedorder.PaymentStatus = 0;
      this.detailsModal = true;
      this.tabIndex = 0;

      const ordervalue = this.clickedCoupon.OrderTotal;
      const balance = this.limitInfo.Credit_Limit_LCY - this.limitInfo.Balance;

      if (balance < ordervalue) {
        this.exceededLimit = true;
      } else {
        this.exceededLimit = false;
      }
    },
    removeOrder(orderid) {
      let orders = this.pendingOrders;
      this.pendingOrders = orders.filter(function(obj) {
        return obj.OrderID !== orderid;
      });
    },
    saveInfo: async function() {
      this.displayErrorMessage = false;
      if (!this.checkPaymentForm()) {
        this.displayErrorMessage = true;
        return;
      }
      this.confirmPayment = true;
      if (
        this.selectedorder.PaymentMode != "Deposit" &&
        this.selectedorder.PaymentMode != "Credit"
      ) {
        //only upload proof if its not deposit
        if (this.checkFileRule(this.document)) {
          const formData = new FormData();
          formData.append(`document`, this.document);
          const uploadresponse = await FileService.uploadFile(formData);
          console.log("upload response path is:", uploadresponse);
          this.selectedorder.PaymentProofURL = uploadresponse
            ? uploadresponse.data.Path
            : "";
        }
      }

      console.log("Selected Order: ", this.selectedorder);
      try {
        if (
          this.selectedorder.PaymentMode == "Deposit" ||
          this.selectedorder.PaymentMode == "Credit"
        ) {
          this.selectedorder.DealerBank = "";
          this.selectedorder.TNLBank = "";
          this.selectedorder.AmountPaid = "0";
          this.selectedorder.PaymentDetails = "";
          this.selectedorder.PaymentProofURL = "";
        }
        const data = null;
        // const { data } = await VehicleService.updateOrderStatus(
        //   this.selectedorder
        // );
        if (data.Data) {
          this.detailsModal = false;
          this.showSuccessAlert();
          this.removeOrder(this.selectedorder.OrderID);
          this.selectedorder = {};
          this.confirmPayment = false;
        } else {
          this.showFailureAlert();
        }
        console.log("data: ", data);
      } catch (ex) {
        this.showFailureAlert();
      }
      this.loading = false;
      // this.detailsModal = false;
    },
    checkPaymentForm() {
      if (!this.selectedorder) {
        return false;
      }
      if (
        this.selectedorder.PaymentMode == "Deposit" ||
        this.selectedorder.PaymentMode == "Credit"
      )
        return true;

      if (
        !this.selectedorder.PaymentMode ||
        !this.selectedorder.AmountPaid ||
        !this.selectedorder.DealerBank ||
        !this.selectedorder.TNLBank
      ) {
        return false;
      }
      return true;
    },
    checkFileRule(file) {
      return /\.(pdf|doc|docx|PDF|DOC|DOCX)$/.test(file.name);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    countDownChangedFail(dismissCountDown) {
      this.dismissCountDownFail = dismissCountDown;
    },
    showSuccessAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    showFailureAlert() {
      this.dismissCountDownFail = this.dismissSecs;
    }
  },
  filters: {},
  watch: {}
};
</script>
<style scoped>
.wizard-navigation .stepTitle span {
  font-size: 2rem !important;
  font-weight: 500;
  line-height: 1.2;
}

.mt-6 {
  margin-top: 4rem !important;
}
</style>

.wizard-navigation .stepTitle span { font-size: 2rem !important; font-weight:
500; line-height: 1.2; } .mt-6 { margin-top: 4rem !important; }
